import React, { useContext, useEffect, useState } from "react";
import { Link, navigate } from "gatsby";
import { useForm, Controller } from "react-hook-form";
import _ from "lodash";
import Skeleton from "react-loading-skeleton";
import PageWrapper from "../components/PageWrapper";
import { Select } from "../components/Core";
import RichTextEditor from "../components/RichTextEditor";
import CustomDatePicker from "../components/DatePicker";
import { AuthContext } from "../context/AuthContext";
import { DataContext } from "../context/DataContext";
import { validation } from "../utils/validation";
import notify from "../utils/notify";
import { updateAccount } from "../api";
import "react-datepicker/dist/react-datepicker.css";

const EditWorkExperience = ({ location }) => {
  const [loading, setLoading] = useState(true);
  const [workExperience, setWorkExperience] = useState(null);
  const [workExperienceIndex, setWorkExperienceIndex] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [submitting, setSubmitting] = useState(false);
  const {
    register,
    errors,
    handleSubmit,
    reset,
    control,
    setValue,
  } = useForm();

  const { auth, getUser } = useContext(AuthContext);
  const { experienceLevels } = useContext(DataContext);

  const fetchUserData = async (accountId) => {
    const response = await getUser(accountId);
    return response;
  };

  useEffect(() => {
    if (location?.state?.workExperienceData) {
      setWorkExperience(location.state.workExperienceData);
      // setStartDate(location.state.workExperienceData.startDate);
      // setEndDate(location.state.workExperienceData.endDate);
      setWorkExperienceIndex(location.state.workExperienceIndex);
      setLoading(false);
      setSubmitting(false);
    } else {
      navigate(-1);
    }
  }, []);

  const onEditorStateChange = (data) => {
    setValue("description", data);
  };

  const startDateChange = (data) => {
    setStartDate(data);
    setValue("startDate", data);
  };

  const endDateChange = (data) => {
    setEndDate(data);
    setValue("endDate", data);
  };

  const onSubmit = async (data) => {
    try {
      setSubmitting(true);
      const talentData = await fetchUserData(auth?.talent.account_id);

      const experience = data.experience.label;
      const formData = _.omit(data, ["experience"]);

      let addedWork = { experience, startDate, endDate, ...formData };

      if (
        talentData.talent.workExperience === undefined ||
        talentData.talent.workExperience === null
      ) {
        talentData.talent.workExperience = [];
      }

      talentData?.talent?.workExperience.splice(
        workExperienceIndex,
        1,
        addedWork
      );

      const payload = {
        ...talentData.talent,
      };

      const talent = auth.talent.account_id;
      const response = await updateAccount(talent, "Talent XP", payload);

      if (response) {
        notify("Work details updated successfully", "success");
        reset();
        navigate("/dashboard-main", { replace: true });
      }
      setSubmitting(false);
    } catch (error) {
      console.log(error);
      notify(error.message);
      setSubmitting(false);
      setLoading(false);
    }
  };

  return (
    <>
      <PageWrapper headerConfig={auth ? { button: "profile" } : null}>
        <div className="bg-default-2 pt-22 pt-lg-25 pb-13 pb-xxl-32">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 dark-mode-texts">
                <div className="mb-9">
                  {loading ? (
                    <Skeleton
                      height={50}
                      containerClassName="w-40"
                      className="w-40"
                    />
                  ) : (
                    <Link
                      to="/#"
                      onClick={(e) => {
                        e.preventDefault();
                        navigate(-1);
                      }}
                      className="d-flex align-items-center ml-4"
                    >
                      {" "}
                      <i className="icon icon-small-left bg-white circle-40 mr-5 font-size-7 text-black font-weight-bold shadow-8"></i>
                      <span className="text-uppercase font-size-3 font-weight-bold text-gray">
                        Back
                      </span>
                    </Link>
                  )}
                </div>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-12">
                {loading ? (
                  <Skeleton
                    height={100}
                    containerClassName="text-center w-100"
                    className="w-70"
                  />
                ) : (
                  <h3 className="card-title font-size-8 font-weight-bold text-center">
                    Edit your work details
                  </h3>
                )}
              </div>
            </div>

            <div className="row justify-content-center pt-12">
              <div className="col-12 col-xxl-7 col-lg-7 col-md-7">
                <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                  {loading ? (
                    <div className="form-group">
                      <Skeleton
                        height={100}
                        containerClassName="text-center w-100"
                        className="w-70"
                      />
                    </div>
                  ) : (
                    <div className="form-group">
                      <label
                        htmlFor="company"
                        className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                      >
                        Company Name*
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={workExperience.company}
                        id="company"
                        name="company"
                        ref={register(validation.company)}
                      />
                      <span className="text-danger small">
                        {errors?.company?.message || ""}
                      </span>
                    </div>
                  )}

                  {loading ? (
                    <div className="form-group">
                      <Skeleton
                        height={100}
                        containerClassName="text-center w-100"
                        className="w-70"
                      />
                    </div>
                  ) : (
                    <div className="form-group">
                      <label
                        htmlFor="position"
                        className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                      >
                        Position*
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={workExperience.position}
                        id="position"
                        name="position"
                        ref={register(validation.position)}
                      />
                      <span className="text-danger small">
                        {errors?.position?.message || ""}
                      </span>
                    </div>
                  )}

                  {loading ? (
                    <div className="form-group">
                      <Skeleton
                        height={400}
                        containerClassName="text-center w-100"
                        className="w-70"
                      />
                    </div>
                  ) : (
                    <div className="form-group">
                      <label
                        htmlFor="description"
                        className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                      >
                        Description*
                      </label>
                      <Controller
                        name="description"
                        control={control}
                        defaultValue=""
                        rules={validation.description}
                        as={
                          <RichTextEditor
                            onEditorStateChange={onEditorStateChange}
                            initialValue={workExperience.description}
                          />
                        }
                      />
                      <span className="text-danger small">
                        {errors?.description?.message || ""}
                      </span>
                    </div>
                  )}

                  {loading ? (
                    <div className="form-group">
                      <Skeleton
                        height={100}
                        containerClassName="text-center w-100"
                        className="w-70"
                      />
                    </div>
                  ) : (
                    <div className="form-group">
                      <label
                        htmlFor="experience"
                        className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                      >
                        Experience Level*
                      </label>
                      <Controller
                        name="experience"
                        control={control}
                        defaultValue={
                          experienceLevels[
                            experienceLevels.findIndex(
                              ({ label }) => label === workExperience.experience
                            )
                          ]
                        }
                        rules={validation.experience}
                        as={
                          <Select
                            options={experienceLevels}
                            className="pl-0 arrow-3 arrow-3-black min-width-px-273 text-black-2 d-flex align-items-center form-control"
                            border={false}
                            id="experience"
                          />
                        }
                      />
                      <span className="text-danger small">
                        {errors?.experience?.message || ""}
                      </span>
                    </div>
                  )}

                  {loading ? (
                    <div className="form-group">
                      <Skeleton
                        height={100}
                        containerClassName="text-center w-100"
                        className="w-70"
                      />
                    </div>
                  ) : (
                    <div className="form-row justify-content-between">
                      <div className="col">
                        <label
                          htmlFor="startDate"
                          className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                        >
                          Start Date
                        </label>
                        <Controller
                          name="startDate"
                          control={control}
                          defaultValue={
                            new Date(
                              workExperience.startDate.seconds * 1000 +
                                workExperience.startDate.nanoseconds / 1000000
                            )
                          }
                          rules={validation.startDate}
                          as={
                            <CustomDatePicker
                              className="form-control"
                              initialvalue={
                                startDate ||
                                workExperience.startDate.seconds * 1000 +
                                  workExperience.startDate.nanoseconds / 1000000
                              }
                              name="startDate"
                              dateChange={startDateChange}
                            />
                          }
                        />
                        <span className="text-danger small">
                          {errors?.startDate?.message || ""}
                        </span>
                      </div>

                      <div className="col ">
                        <label
                          htmlFor="endDate"
                          className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                        >
                          End Date
                        </label>
                        <Controller
                          name="endDate"
                          control={control}
                          defaultValue={
                            new Date(
                              workExperience.endDate.seconds * 1000 +
                                workExperience.endDate.nanoseconds / 1000000
                            )
                          }
                          rules={validation.endDate}
                          as={
                            <CustomDatePicker
                              className="form-control"
                              initialvalue={
                                endDate ||
                                workExperience.endDate.seconds * 1000 +
                                  workExperience.endDate.nanoseconds / 1000000
                              }
                              name="endDate"
                              dateChange={endDateChange}
                            />
                          }
                        />

                        <span className="text-danger small">
                          {errors?.endDate?.message || ""}
                        </span>
                      </div>
                    </div>
                  )}

                  <div className="form-group mb-8">
                    {loading ? (
                      <Skeleton
                        height={100}
                        containerClassName="text-center w-100"
                        className="w-70"
                      />
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-primary btn-medium w-100 rounded-5 text-uppercase focus-reset"
                        disabled={submitting || loading ? true : false}
                      >
                        {submitting ? "Submitting..." : `Edit Experience`}
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    </>
  );
};

export default EditWorkExperience;
